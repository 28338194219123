<template>
    <div id="build-details-form">
        <div class="top">
            <div class="top__left">
                <h2 class="mb-3">
                    Connect Snapchat Assets
                </h2>
                <p class="desc">
                    Looking good! To deploy playbooks with Snapchat you'll need to connect the following assets from your
                    Snapchat account. This requires that you have each setup correctly so we highly encourage you to watch
                    out video tutorial to the right.
                </p>
            </div>
            <div class="top__right">
                <action-button
                    color="#00A4EB"
                    width="55"
                    height="55"
                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding"
                    target="_blank"
                    icon="book">
                    <div class="dark-word">
                        Read our<br>guide
                    </div>
                </action-button>
            </div>
            <div class="top__right">
                <action-button
                    class="video-icon"
                    color="#00A4EB"
                    width="55"
                    height="55"
                    href="https://www.youtube.com/watch?v=w7v5bJl6c10"
                    target="_blank"
                    icon="video">
                    <div class="dark-word">
                        Watch video tutorial
                    </div>
                </action-button>
            </div>
        </div>

        <v-form
            ref="form"
            v-model="formValid">
            <div class="build-details">
                <loader v-if="loading" />
                <div v-else>
                    <div
                        v-if="errorMessage"
                        class="err-message">
                        {{ errorMessage }}
                    </div>

                    <!-- BUSINESS MANAGER -->
                    <div
                        v-if="!businessManagerAvailable"
                        class="mb-5">
                        <h2>Business Manager</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Choose your business manager
                                </p>
                            </div>
                            <action-button
                                v-if="businessLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!businessLoading"
                                class="block__select">
                                <v-select
                                    v-model="businessManager"
                                    placeholder="Select Account..."
                                    :rules="requiredRules"
                                    :items="businessManagers"
                                    label="Business Manager"
                                    class="styled-field" />
                            </div>
                            <div v-if="liteCompleted">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!businessLoading"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    @click.prevent="getBusinessManagers">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    class="icon"
                                    width="25"
                                    height="25"
                                    icon="book"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#business-manager-creation"
                                    target="_blank"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                                <action-button
                                    class="video-icon icon"
                                    width="25"
                                    height="25"
                                    href="https://www.youtube.com/watch?v=w7v5bJl6c10&t=69s"
                                    target="_blank"
                                    icon="video"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        VIDEO
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                    <!-- AD ACCOUNT -->
                    <div v-if="hasAdAccountSetup">
                        <h2>Ad Account</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Choose the ad account in Snapchat you want to associate with
                                    {{ dealer.name }}
                                    and ensure it has billing setup.
                                </p>
                            </div>
                            <action-button
                                v-if="accountLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!accountLoading && !adAccountComplete"
                                class="block__select">
                                <v-select
                                    v-model="account"
                                    :rules="requiredRules"
                                    :disabled="businessManager == ''"
                                    placeholder="Select Account..."
                                    :items="accounts"
                                    label="AD ACCOUNT"
                                    class="styled-field" />
                            </div>
                            <div v-if="adAccountComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!adAccountComplete"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    :disabled="businessManager == ''"
                                    @click.prevent="getAdAccounts">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!adAccountComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#setting-up-ad-accounts"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!adAccountComplete"
                                    class="video-icon icon"
                                    width="25"
                                    height="25"
                                    href="https://www.youtube.com/watch?v=w7v5bJl6c10&t=98s"
                                    target="_blank"
                                    icon="video"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        VIDEO
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- PIXEL -->
                    <div v-if="hasPixelSetup">
                        <h2 class="mt-5">
                            Pixel
                        </h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Choose the pixel in Snapchat you want to associate with {{ dealer.name }}
                                </p>
                            </div>
                            <action-button
                                v-if="pixelLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!pixelLoading && !pixelComplete"
                                class="block__select">
                                <v-select
                                    v-model="pixel"
                                    :rules="requiredRules"
                                    placeholder="Select pixel..."
                                    :items="pixels"
                                    :disabled="!accountId"
                                    label="PIXEL"
                                    class="styled-field" />
                            </div>
                            <div v-if="pixelComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!pixelComplete"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    :disabled="!accountId"
                                    @click.prevent="getPixel">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!pixelComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#creating-a-pixel"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!pixelComplete"
                                    class="video-icon icon"
                                    width="25"
                                    height="25"
                                    href="https://www.youtube.com/watch?v=w7v5bJl6c10&t=249s"
                                    target="_blank"
                                    icon="video"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        VIDEO
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>


                    <!-- IDENTITY -->
                    <div v-if="hasPublicProfileSetup">
                        <h2 class="mt-5">
                            Public Profile
                        </h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Choose your public profile in Snapchat that you want to associate with your Snapchat Onboarding
                                </p>
                            </div>
                            <action-button
                                v-if="publicProfilesLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!publicProfilesLoading && !publicProfileComplete"
                                class="block__select">
                                <v-select
                                    v-model="publicProfile"
                                    :rules="requiredRules"
                                    placeholder="Select public profile..."
                                    :items="publicProfiles"
                                    :disabled="adAccountComplete ? false : !account"
                                    label="PUBLIC PROFILE"
                                    class="styled-field" />
                            </div>
                            <div v-if="publicProfileComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!publicProfileComplete"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    :disabled="adAccountComplete ? false : !account"
                                    @click.prevent="getPublicProfiles">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!publicProfileComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#create-snapchat-public-profile"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- CATALOGS -->
                    <div v-if="hasCatalogSetup">
                        <h2 class="mt-5">
                            Catalog
                        </h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Choose the catalog in Snapchat you want to associate with {{ dealer.name }}
                                </p>
                            </div>
                            <action-button
                                v-if="catalogsLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!catalogsLoading && !catalogComplete"
                                class="block__select">
                                <v-select
                                    v-model="catalog"
                                    :rules="requiredRules"
                                    :disabled="businessManager == ''"
                                    placeholder="Select catalog..."
                                    :items="catalogs"
                                    label="CATALOG"
                                    class="styled-field" />
                            </div>
                            <div v-if="catalogComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    :disabled="businessManager == ''"
                                    @click.prevent="getCatalogs">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#creating-a-catalog"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!catalogComplete"
                                    class="video-icon icon"
                                    width="25"
                                    height="25"
                                    href="https://www.youtube.com/watch?v=w7v5bJl6c10&t=286s"
                                    target="_blank"
                                    icon="video"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        VIDEO
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="hasCatalogSetup">
                    <div class="url-field">
                        <p class="desc mb-2">
                            During setup you'll need the feed URL below:
                        </p>
                        <div class="field-holder">
                            <action-button
                                v-if="urlLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <v-text-field
                                v-else
                                ref="urlInput"
                                v-model="url"
                                readonly
                                class="styled-field" />
                            <action-button
                                v-if="!copied"
                                class="copy"
                                width="27"
                                height="27"
                                icon="copy"
                                @click.prevent="copy" />
                            <span
                                v-else
                                class="copied">Copied!</span>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="trouble"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket')">Having trouble? Connect with support</a>
                </div>
            </div>
            <div class="button-wrapper">
                <styled-button
                    v-if="submitInProgress"
                    class="styled-button"
                    :disabled="true"
                    :loading="submitInProgress"
                    @click.prevent="submit">
                    WAIT...
                </styled-button>
                <styled-button
                    v-else
                    class="styled-button"
                    :disabled="!valid"
                    @click.prevent="submit">
                    CONTINUE
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import validationRules from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import { PLATFORM_ID_SNAPCHAT } from '@/helpers/globals';

    export default {
        components: {
            ActionButton,
            StyledButton,
            Loader,
            StatusIcon
        },
        data() {
            return {
                requiredRules: validationRules.requiredRules,
                formValid: false,

                businessManager: '',
                businessManagers: [],
                businessManagerId: '',
                businessManagersData: [],
                businessManagerAvailable: false,
                businessLoading: false,

                account: '',
                accounts: [],
                accountId: '',
                accountsData: [],
                accountLoading: false,

                pixel: '',
                pixels: [],
                pixelId: '',
                pixelsData: [],
                pixelLoading: false,

                publicProfile: '',
                publicProfiles: [],
                publicProfileId: '',
                publicProfilesData: [],
                publicProfilesLoading: false,

                catalog: '',
                catalogs: [],
                catalogId: '',
                catalogsData: [],
                catalogsLoading: false,

                url: '',
                urlLoading: true,
                copied: false,
                loading: true,
                errorMessage: '',
                submitInProgress: false,
            };
        },
        computed: {
            ...mapState({
                onboardingProducts: (state) => state.onboarding.products,
                dealer: (state) => state.dealer.currentDealer,
            }),
            ...mapGetters([
                'dealerFeaturesComplete',
                'dealerFeaturesSetup',
                'requiredFeatures'
            ]),
            hasAdAccountSetup() {
                return this.requiredFeatures.includes('snapchat_ad_account');
            },
            adAccountComplete() {
                return this.dealerFeaturesComplete.includes('snapchat_ad_account');
            },
            hasPixelSetup() {
                return this.requiredFeatures.includes('snapchat_pixel');
            },
            pixelComplete() {
                return this.dealerFeaturesComplete.includes('snapchat_pixel');
            },
            hasPublicProfileSetup() {
                return this.requiredFeatures.includes('snapchat_public_profile');
            },
            publicProfileComplete() {
                return this.dealerFeaturesComplete.includes('snapchat_public_profile');
            },
            hasCatalogSetup() {
                return this.requiredFeatures.includes('snapchat_catalog');
            },
            catalogComplete() {
                return this.dealerFeaturesSetup.includes('snapchat_catalog');
            },
            allAssetsSetup() {
                return (
                    (this.hasAdAccountSetup ? this.adAccountComplete : true) &&
                    (this.hasPixelSetup ? this.pixelComplete : true) &&
                    (this.hasPublicProfileSetup ? this.publicProfileComplete : true) &&
                    (this.hasCatalogSetup ? this.catalogComplete : true)
                );
            },
            localRequiredFeatures() {
                const localFeatures = [
                    'snapchat_ad_account',
                    'snapchat_pixel',
                    'snapchat_catalog',
                    'snapchat_public_profile'
                ];

                return this.requiredFeatures.filter(feature => (
                    localFeatures.includes(feature)
                ));
            },
            valid() {
                return (
                    this.businessManagerId &&
                    ((this.hasAdAccountSetup && !this.adAccountComplete) ? this.account : true) &&
                    ((this.hasPixelSetup && !this.pixelComplete) ? this.pixel : true) &&
                    ((this.hasPublicProfileSetup && !this.publicProfileComplete) ? this.publicProfile : true) &&
                    ((this.hasCatalogSetup && !this.catalogComplete) ? this.catalog : true)
                );
            },
            existingAdAccountId() {
                return this.dealer.ad_accounts.data.find(a => a.platform_id == 9)?.external_ad_account_id ?? null;
            }
        },
        watch: {
            businessManager() {
                const currentBM = this.businessManagersData.filter(e => e.name == this.businessManager);
                this.businessManagerId = currentBM[0].id;
                this.businessManagerChanged();
            },
            account() {
                if (this.account) {
                    const currentAccount = this.accountsData.filter(e => e.name == this.account);
                    this.accountId = currentAccount[0].id;
                    this.getPixel();
                    this.getPublicProfiles();
                }
            },
            catalog() {
                if (this.catalog) {
                    const currentCatalog = this.catalogsData.filter(e => e.name == this.catalog);
                    this.catalogId = currentCatalog[0].id;
                }
            },
            publicProfile() {
                if (this.publicProfile) {
                    const currentPublicProfile = this.publicProfilesData.filter(e => e.name == this.publicProfile);
                    this.publicProfileId = currentPublicProfile[0]?.id;
                }
            },
            pixel() {
                if (this.pixel) {
                    const currentPixel = this.pixelsData.filter(e => e.name == this.pixel);
                    this.pixelId = currentPixel[0]?.id;
                }
            }
        },
        async mounted() {
            await this.initAll();
        },
        methods: {
            async initGeneratingRemoteFiles() {
                const fileId = {
                    export_format_ids: []
                };
                if (this.onboardingProducts[0].platform_id == 9) {
                    fileId.export_format_ids = [14];
                }
                if (this.onboardingProducts[0].platform_id == 8) {
                    fileId.export_format_ids = [13];
                }
                try {
                    const generateRemoteFile = await this.$http.post(`/dealers/${this.dealer.id}/upload-exports`, fileId);
                    console.log(generateRemoteFile);
                } catch(error) {
                    console.log(error);
                }
            },
            async initAll() {
                if (this.hasCatalogSetup) {
                    await this.initGeneratingRemoteFiles();
                }

                this.loading = true;

                await this.checkForExistingBM();
                if (this.adAccountComplete) {
                    await this.getPublicProfiles();
                    const currentAccount = this.dealer.ad_accounts.data.find(account => account.platform_id == PLATFORM_ID_SNAPCHAT);
                    this.accountId = currentAccount.external_ad_account_id;
                    this.getPixel();
                }
                this.loading = false;

                if (this.hasCatalogSetup) {
                    await this.getURL();
                }
            },
            async businessManagerChanged() {
                await this.createNewBusinessManager();
                await this.getAdAccounts();
                if (this.hasCatalogSetup) {
                    await this.getCatalogs();
                }
            },
            async checkForExistingBM() {
                const existingBMs = this.dealer.agency.business_managers?.data;
                let availableBMid = '';
                existingBMs.forEach(manager =>{
                    if (manager.platform_id == 9) {
                        this.businessManagersData.push({
                            'name': manager.name,
                            'id': manager.external_business_manager_id
                        });
                        this.businessManager = manager.name;
                        availableBMid = manager.external_business_manager_id;
                    }
                });
                if (availableBMid) {
                    this.businessManagerAvailable = true;
                    this.businessManagerId = availableBMid;
                    await this.createNewBusinessManager();
                    await this.getAdAccounts();
                } else {
                    await this.getBusinessManagers();
                }
            },
            async getBusinessManagers() {
                try {
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/platforms/snapchat/business-managers`);
                    this.businessManagersData = [];
                    this.businessManagers = [];
                    response.data.forEach(e => {
                        this.businessManagersData.push({
                            'name': e.name,
                            'id': e.external_id
                        });
                        this.businessManagers.push(e.name);
                    });
                } catch(error) {
                    this.loading = false;
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async getAdAccounts() {
                try {
                    this.accountLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/platforms/snapchat/business-managers/ad-accounts/`);
                    this.accountsData = [];
                    this.accounts = [];
                    this.account = '';
                    this.pixel = '';
                    this.publicProfile = '';
                    this.catalog = '';
                    response.data.forEach(e => {
                        this.accountsData.push({
                            'name': e.name,
                            'id': e.external_id
                        });
                        this.accounts.push(e.name);
                    });
                } catch(error) {
                    this.accountLoading = false;
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    this.accountLoading = false;
                }
            },
            async getPixel() {
                try {
                    this.pixelLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/platforms/snapchat/ad-accounts/${this.accountId}/pixels/`);
                    this.pixels = [];
                    this.pixelsData = [];
                    this.pixel = '';
                    response.data.forEach(e => {
                        this.pixelsData.push({
                            'name': e?.name,
                            'id': e?.external_id?.toString(),
                        });
                        this.pixels.push(e.name);
                    });
                } catch(error) {
                    this.pixelLoading = false;
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    this.pixelLoading = false;
                }
            },
            async getCatalogs() {
                this.catalogsLoading = true;
                try {
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/platforms/snapchat/organizations/${this.businessManagerId}/catalogs`);
                    this.catalogs = [];
                    this.catalogsData = [];
                    this.catalog = '';
                    response.data.forEach(e => {
                        this.catalogsData.push({
                            'name': e.name,
                            'id': e.external_id
                        });
                        this.catalogs.push(e.name);
                    });
                } catch(error) {
                    this.catalogsLoading = false;
                    console.log('Error:', error);
                    this.errorMessage = error;
                } finally {
                    this.catalogsLoading = false;
                }
            },
            async getPublicProfiles() {
                this.publicProfilesLoading = true;
                try {
                    let accountId = '';
                    if (this.adAccountComplete) {
                        accountId = this.existingAdAccountId;
                    } else {
                        accountId = this.accountId;
                    }
                    const response = await this.$apiRepository.getSnapchatPublicProfiles(this.dealer.id, accountId);
                    this.publicProfiles = [];
                    this.publicProfilesData = [];
                    this.publicProfile = '';
                    response.data.forEach(e => {
                        this.publicProfilesData.push({
                            'name': e?.name,
                            'id': e?.external_id?.toString()
                        });
                        this.publicProfiles.push(e.name);
                    });
                } catch(error) {
                    this.publicProfilesLoading = false;
                    console.log('Error:', error);
                    this.errorMessage = error;
                } finally {
                    this.publicProfilesLoading = false;
                }
            },
            async getURL() {
                try {
                    this.urlLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/get-remote-files`);
                    //console.log(response.data);
                    const remoteFiles = response.data;
                    remoteFiles.forEach(file =>{
                        if (file.remote_file_type.name.includes('Snapchat')) {
                            this.url = file.url;
                        }
                    });
                } catch(error) {
                    console.log(error);
                } finally {
                    if (this.url) {
                        this.urlLoading = false;
                    } else {
                        setTimeout(() => {
                            this.getURL();
                        }, 10000);
                    }
                }
            },
            async sendAllData() {
                if (this.hasAdAccountSetup && !this.adAccountComplete) {
                    await this.createNewAdAccount();
                }

                if (this.hasPixelSetup && !this.pixelComplete) {
                    await this.createNewPixel();
                }

                if (this.hasPublicProfileSetup && !this.publicProfileComplete) {
                    await this.createNewPublicProfile();
                }

                if (this.hasCatalogSetup && !this.catalogComplete) {
                    await this.createNewCatalog();
                }

                await this.checkFeatureStatus();
            },
            checkFeatureStatus() {
                return this.$store.dispatch('updateOnboardingStatus', {
                    features: this.localRequiredFeatures
                });
            },
            async createNewBusinessManager() {
                const BMData = {
                    agency_id: this.dealer.agency.id,
                    platform_id: 9,
                    external_business_manager_id: this.businessManagerId,
                };
                try {
                    const BM = this.dealer.agency.business_managers?.data.find(bm => {
                        return bm.platform_id == 9;
                    });
                    if (BM) {
                        const changingBM = await this.$http.put(`/business_managers/${BM.id}`, BMData);
                        console.log(changingBM);
                    } else {
                        const newBM = await this.$http.post('/business_managers', BMData);
                        console.log(newBM);
                    }
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    await this.$store.dispatch('updateDealerById', this.dealer.id);
                    await this.$store.dispatch('updateOnboardingStatus');
                }
            },
            async createNewAdAccount() {
                const adAccountData = {
                    dealer_id: this.dealer.id,
                    platform_id: 9,
                    external_ad_account_id: this.accountId,
                };
                try {
                    const newAdAccount = await this.$http.post('/ad_accounts', adAccountData);
                    console.log(newAdAccount);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewCatalog() {
                const catalogData = {
                    dealer_id: this.dealer.id,
                    platform_id: 9,
                    external_catalog_id: this.catalogId,
                };
                try {
                    const newCatalog = await this.$http.post('/catalogs', catalogData);
                    console.log(newCatalog);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewPixel() {
                const pixelData = {
                    dealer_id: this.dealer.id,
                    platform_id: 9,
                    external_pixel_id: this.pixelId,
                };
                try {
                    const newPixel = await this.$http.post('/pixels', pixelData);
                    console.log(newPixel);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewPublicProfile() {
                const publicProfileData = {
                    dealer_id: this.dealer.id,
                    platform_id: 9,
                    external_identity_id: this.publicProfileId,
                };
                try {
                    const newIdentity = await this.$http.post('/identities', publicProfileData);
                    console.log(newIdentity);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async submit() {
                if (this.allAssetsSetup) {
                    this.$store.dispatch('goToNextStep');
                    return;
                }

                this.$refs.form.validate();
                if (!this.formValid) {
                    console.log('Please provide all required data');
                    return;
                }

                this.submitInProgress = true;
                this.errorMessage = '';
                await this.sendAllData();

                if (this.allAssetsSetup) {
                    this.$store.dispatch('goToNextStep');
                    this.submitInProgress = false;
                    return;
                }

                if (this.errorMessage) {
                    this.submitInProgress = false;
                }
            },
            copy() {
                const copyText = this.$refs.urlInput;
                navigator.clipboard.writeText(copyText.value).then(() => {
                    this.copied = true;
                }).catch(err => {
                    console.log('Something went wrong with copying:', err);
                });
            },
        }
    };
</script>

<style lang="scss">
#build-details-form{
    .top{
        display: flex;
        justify-content: space-between;
        &__right{
            padding: 30px;
            min-width: 130px;
        }
    }
    .desc{
        font-size:14px;
    }
    .build-details{
        padding: 30px;
        margin-top: 15px;
        border-top: 1px solid $gray-light;
    }
    .dark-word{
        color:$gray-dark;
        font-size: 10px;
        line-height: 14px;
    }
    .icon{
        margin-left: 30px;
    }
    .video-icon{
        path{
            transform: translateY(-2px);
        }
    }
    .block{
        //justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        &__icons{
            white-space: nowrap;
            width: 20%;
        }
        &__info{
            width: 100%;
            margin-bottom: 50px;
            @media (min-width: $bp-lg) {
                width: 40%;
                margin-right: 5%;
                margin-bottom: 0;
            }
        }
        &__select{
            width: 30%;
            margin-right: 5%;
            //width: 220px;
            //margin-left: 60px;
            .v-select__selections{
                min-height: 40px;
                //width: 220px;
                overflow: hidden;
            }
        }
    }
    .button-wrapper{
        text-align: right;
        padding: 0 30px;
    }
    .styled-button{
        font-size: 14px;
    }
    .trouble{
        margin-top:40px;
        display: inline-block;
        font-size: 14px;
    }
    .url-field{
        margin-top:30px;
        max-width: 400px;
        input{
            padding-top:17px;
            padding-bottom:17px;
        }
    }
    .field-holder{
        display: flex;
    }
    .copy{
        margin-left: 15px;
        transform: translateY(2px);
    }
    .copied{
        color: green;
        margin-left: 10px;
        font-size: 14px;
        margin-top:10px;
    }
    .err-message{
        padding: 0 30px;
        margin-bottom: 30px;
        text-align: center;
        color: red;
    }
    .sp{
        padding-top:2px;
    }
}
</style>